<template>
  <b-modal
    id="id-modal-reservation-sms"
    :title="$t('reservation.exportSms')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    body-class="p-1"
    centered
    no-close-on-backdrop
    size="lg"
    @show="onShowModalSms()"
  >
    <div class="text-danger">
      {{ $t('reservation.sms.note') }}
    </div>

    <b-tabs
      v-model="tabIndex"
      lazy
    >
      <b-tab
        active
        :title="$t('reservation.sms.vnWithAccent')"
      >
        <b-form-textarea
          v-model="smsContents[0]"
          rows="16"
          style="font-size: 16px; line-height: 24px;"
        />
      </b-tab>

      <b-tab :title="$t('reservation.sms.vnNoAccent')">
        <b-form-textarea
          v-model="smsContents[1]"
          rows="16"
          style="font-size: 16px; line-height: 24px;"
        />
      </b-tab>

      <b-tab :title="$t('reservation.sms.en')">
        <b-form-textarea
          v-model="smsContents[2]"
          rows="16"
          style="font-size: 16px; line-height: 24px;"
        />
      </b-tab>
      <template #tabs-end>
        <div
          v-if="ticketData.roundtripBooking || (ticketData.multiCityBookings && ticketData.multiCityBookings.length)"
          class="flex-fill d-flex-center justify-content-end"
        >
          <BFormCheckbox
            id="checkbox-combinate-sms"
            v-model="isCombinateSMS"
            lazy-formatter
            @click="() => isCombinateSMS = !isCombinateSMS"
          >
            {{ $t('reservation.sms.combinate') }}
          </BFormCheckbox>
        </div>
      </template>
    </b-tabs>

    <template #modal-footer>
      <b-button
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="outline-danger"
        pill
        @click="$root.$emit('bv::hide::modal', 'id-modal-reservation-sms')"
      >
        {{ $t('customer.cancel') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient width-150 mt-lg-25"
        pill
        :disabled="disabledCopy"
        @click="copySmsContentToClipboard()"
      >
        {{ $t('reservation.copy') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  ref, watch, getCurrentInstance, computed,
} from '@vue/composition-api'
import {
  BModal,
  BTabs,
  BTab,
  BFormTextarea,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { useClipboard } from '@vueuse/core'

import store from '@/store'
import {
  getBaggagesByBookingClass,
  getHandBaggage,
  getFreeBaggage,
} from '@/views/apps/configs/ticket/useSegmentBaggage'
import { resolveSsrNameByCode } from '@/constants/selectOptions'

import {
  formatCurrency,
  convertISODateTime,
  getLongWeekdayFromIso,
  resolveAirlineFlightNumber,
  cutHonorificFromFirstName,
} from '@core/utils/filter'
import { removeAccents } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  name: 'ModalSms',

  components: {
    BModal,
    BTabs,
    BTab,
    BFormTextarea,
    BButton,
    BFormCheckbox,
  },

  props: {
    ticketData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  setup(props) {
    const {
      getBaggageInBookingDetail, resolveSsrData, resolveBaggageBySegment, resolveSegmentData,
    } = useReservationHandle()
    const tabIndex = ref(0)
    const smsContents = ref(['', '', ''])
    const disabledCopy = ref(false)
    const isCombinateSMS = ref(true) // tách gộp SMS

    const employeeConfig = computed(() => store.getters['userStore/getEmployeeConfig'])

    const { copy } = useClipboard()
    function copySmsContentToClipboard() {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(smsContents.value[tabIndex.value])
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(error => {
          this.$root.toastError('reservation.sms.copyFail')
          console.error(this.$t('reservation.sms.copyFail'), error)
        })
    }

    function getAirportByAirportCode(airportCode) {
      return store.getters['app-reservation/getAirportByAirportCode'](airportCode)
    }

    function getAirlineNameByCode(code) {
      return store.getters['globalConfig/getAirlineNameByCode'](code)
    }

    // SECTION: NEW function - by duclt
    function convertFlatTicketData(ticketData, isCombinate) {
      const array = [ticketData]
      if (isCombinate && ticketData?.roundtripBooking) {
        array.push(ticketData?.roundtripBooking)
      }
      if (isCombinate && ticketData?.multiCityBookings && ticketData?.multiCityBookings.length) {
        array.push(...ticketData?.multiCityBookings)
      }

      try {
        array.sort((a, b) => {
          if (!a?.itineraries?.length || !b?.itineraries?.length) return 0
          const departureA = new Date(a.itineraries[0][0].departure.at)
          const departureB = new Date(b.itineraries[0][0].departure.at)
          return departureA - departureB
        })
      } catch (error) {
        console.error({ error })
        smsContents.value = [
          'Chuyến bay đã bị huỷ',
          'Chuyen bay da bi huy',
          'This flight have been cancelled',
        ]
        return null
      }

      return array
    }

    function getSmsContent(t, isVietnamese, isRemoveAccents = false, isCombinate = true) {
      const ticketData = convertFlatTicketData(props.ticketData, isCombinate)
      if (!ticketData) return 'Chuyến bay đã bị huỷ'
      let result = ''

      ticketData.forEach(ticket => {
        // ANCHOR pax
        console.log({
          ticket,
          paxLists: ticket.paxLists,
        })
        const paxs = ticket.paxLists.map(pax => {
          const { firstName, honorific } = cutHonorificFromFirstName(pax.firstName)
          const title = pax.title || honorific
          const paxType = pax.paxType
          // let bags = null
          // if (paxType === 'ADULT') {
          //   bags = allSsrBag.filter(item => item.paxId === pax.paxId)
          // }

          return {
            passenger: {
              paxType,
              title,
              firstName,
              lastName: pax.lastName,
            },
            // bags,
          }
        })
        paxs.forEach(item => {
          const fullName = isVietnamese
            ? `${item.passenger.lastName} ${item.passenger.firstName}`
            : `${item.passenger.firstName} ${item.passenger.lastName}`

          result += `${fullName}\n`
        })

        // ANCHOR airlines
        let airline = ticket.airlines.split(' | ')[0] || ticket.source
        if (airline === 'VN') {
          if (ticket.itineraries.every(trip => JSON.stringify(trip).includes('"BL"'))) {
            airline = 'BL'
          } else if (ticket.itineraries.some(trip => JSON.stringify(trip).includes('"BL"'))) {
            airline = 'VN|BL'
          }
        }
        const textAirline = t('promotionAg.tableTitle.airline')
        result += `${textAirline} ${airline.split('|').map(airlineItem => getAirlineNameByCode(airlineItem)).join(', ')}\n`

        // ANCHOR booking Code
        const bookingCode = ticket.bookingCode
        const textReservationCode = t('reservation.code')
        result += `${textReservationCode}: ${bookingCode}\n`

        // ANCHOR: itineraries
        if (!ticket?.itineraries?.length) {
          result += `${t('reservation.flightHasBeenCancelled')}\n`
        } else {
          ticket.itineraries.forEach(trip => {
            trip.forEach(segment => {
            // name of departure airport
              const departureAirportObj = getAirportByAirportCode(segment.departure.iataCode)
              let departureAirportName
              if (departureAirportObj?.iata && departureAirportObj?.cityName && departureAirportObj?.cityEnName && departureAirportObj?.countryName && departureAirportObj?.countryEnName) {
                departureAirportName = isVietnamese ? `${departureAirportObj.cityName}, ${departureAirportObj?.countryName}` : `${departureAirportObj.cityEnName}, ${departureAirportObj?.countryEnName}`
              } else if (departureAirportObj?.group === 'VIỆT NAM' || departureAirportObj?.group === 'VIETNAM') {
                departureAirportName = `${t(`vnAirports.${segment.departure.iataCode}`)}`
              } else {
                departureAirportName = `${departureAirportObj?.name}`
              }

              // name of arrival airport
              const arrivalAirportObj = getAirportByAirportCode(segment.arrival.iataCode)
              console.log({ arrivalAirportObj, iataCode: segment.arrival.iataCode })
              let arrivalAirportName
              if (arrivalAirportObj?.iata && arrivalAirportObj?.cityName && arrivalAirportObj?.cityEnName && arrivalAirportObj?.countryName && arrivalAirportObj?.countryEnName) {
                arrivalAirportName = isVietnamese ? `${arrivalAirportObj.cityName}, ${arrivalAirportObj?.countryName}` : `${arrivalAirportObj.cityEnName}, ${arrivalAirportObj?.countryEnName}`
              } else if (arrivalAirportObj?.group === 'VIỆT NAM' || arrivalAirportObj?.group === 'VIETNAM') {
                arrivalAirportName = `${t(`vnAirports.${segment.arrival.iataCode}`)}`
              } else {
                arrivalAirportName = `${arrivalAirportObj?.name}`
              }

              const textRoute = t('reservation.route')
              const textItineraries = `* ${textRoute} ${departureAirportName} - ${arrivalAirportName}\n`

              result += isRemoveAccents || !isVietnamese ? removeAccents(textItineraries) : textItineraries

              const textFlight = t('ticket.flightsInfo.flight')
              result += `${textFlight} ${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)} ${segment?.operating === 'BL' && airline !== 'BL' ? `(${getAirlineNameByCode(segment?.operating)})` : ''}\n`

              const textDepartTime = t('reservation.at')
              // const textDepartDate = t('reservation.date') // a công bảo bỏ text `ngày`
              const lang = isVietnamese ? 'vi' : 'en'
              const segDepartWeekday = getLongWeekdayFromIso(
                segment.departure.at,
                segment.departure.timeZone,
                lang,
              )
              const segDepartTime = convertISODateTime(
                segment.departure.at,
                segment.departure.timeZone,
              ).time
              const segDepartDate = convertISODateTime(
                segment.departure.at,
                segment.departure.timeZone,
              ).date
              const departWeekday = isVietnamese && this.$te(segDepartWeekday)
                ? t(segDepartWeekday)
                : segDepartWeekday
              result += `${textDepartTime} ${segDepartTime} - ${segDepartDate} (${departWeekday})\n`
            })
          })
        }
        result += '-------***-------\n'
        // ANCHOR: baggage - lại mở lên
        // ticketData.forEach(ticket => {
        ticket.itineraries.forEach(trip => {
          trip.forEach(segment => {
            const bag = getBaggageInBookingDetail(segment, ticket)
            const carryBagTemp = !isVietnamese && bag.carryBag === 'Không bao gồm' ? 'Not included' : (isVietnamese && bag.carryBag === 'Not included' ? 'Không bao gồm' : bag.carryBag)
            const checkingBagTemp = !isVietnamese && bag.checkinBag === 'Không bao gồm' ? 'Not included' : (isVietnamese && ['Not included', 'Không bao gồm'].includes(bag.checkinBag) ? 'Không ký gửi' : bag.checkinBag)
            const textHandBaggage = t('ticket.flightsInfo.handBaggage')
            const textFreeBaggage = t('ticket.flightsInfo.freeBaggage')
            if (carryBagTemp) {
              result += `${segment.departure.iataCode}${segment.arrival.iataCode}: ${textHandBaggage} ${isRemoveAccents
                ? removeAccents(carryBagTemp)
                : (!isVietnamese && carryBagTemp ? carryBagTemp.replaceAll('kiện', 'PC') : carryBagTemp)}\n`
            }
            if (checkingBagTemp) {
              result += `${segment.departure.iataCode}${segment.arrival.iataCode}: ${['Không ký gửi'].includes(checkingBagTemp) ? '' : textFreeBaggage} ${isRemoveAccents
                ? removeAccents(checkingBagTemp)
                : (!isVietnamese && checkingBagTemp ? checkingBagTemp.replaceAll('kiện', 'PC') : checkingBagTemp)}\n`
            }
          })
        })
        // })

        // ANCHOR: baggages add on - a Công bảo thêm config hiện ra
        ticketData.forEach(ticket => {
          if (!employeeConfig.value.showAddonForSms) return
          if (ticket?.ancillaryServices?.length) {
            result += '-------***-------\n'
            const textBaggageWithFee = t('reservation.baggageWithFee')
            result += `${textBaggageWithFee}:\n`
          }
          if (['1S', 'VN1A', 'VN1A_MT'].includes(ticket.source)) {
            const bagsAddOn = resolveSsrData(ticket)
            // console.log({ bagsAddOn })
            let prevPaxId = null
            if (bagsAddOn.length) {
              // result += `${textBaggageWithFee}:\n`
              // result += `${bagsAddOn[0].passenger.lastName} ${bagsAddOn[0].passenger.firstName}\n`
              // result += `${bagsAddOn[0].segments[0].departure.iataCode}${bagsAddOn[0].segments[0].arrival.iataCode}: `
              bagsAddOn.forEach(item => {
                if (item.passenger.paxId !== prevPaxId) {
                  result += `${item.passenger.lastName} ${item.passenger.firstName}\n`
                  prevPaxId = item.passenger.paxId
                }
                // result += `${item.segments[0].departure.iataCode}${item.segments[0].arrival.iataCode}: `
                if (item.serviceType === 'BAGGAGE') {
                  result += `${item.segments[0].departure.iataCode}${item.segments[0].arrival.iataCode}: `
                  result += `${resolveSsrNameByCode(item.serviceSubCode)} x${item.numberOfItems}\n`
                }
              })
            }
          } else {
            ticket.itineraries.forEach(trip => {
              const segments = resolveSegmentData(trip, ticket)
              let prevPaxId

              segments.forEach(seg => {
                const bagsAddOn = resolveBaggageBySegment(seg)
                if (bagsAddOn?.data) {
                  if (seg.passenger.paxId !== prevPaxId) {
                    result += `${seg.passenger.lastName} ${seg.passenger.firstName}\n`
                    prevPaxId = seg.passenger.paxId
                  }
                  result += `${seg.segment.departure.iataCode}${seg.segment.arrival.iataCode}: `
                  bagsAddOn.data.forEach((bag, index) => {
                    result += `${bag.ssrName || bagsAddOn.text}${bag.quantity || bag.numberOfItems ? ` x${bag.quantity || bag.numberOfItems}` : ''}${index !== bagsAddOn.data.length - 1 ? ', ' : '\n'}`
                  })
                }
              })
            })
          }
        })

        // ANCHOR: amount payment
        // result += '-------***-------\n'
        result += '\n'
        const textTotalAmount = t('reservation.totalAmount')
        result += `${textTotalAmount}: ${formatCurrency(ticket?.totalAmountTicket ?? ticket?.fareInfoTicket?.total)} VND\n`
        // additional notes
        const textNote1 = ticket.isDomestic ? t('reservation.sms.note1Domestic') : t('reservation.sms.note1International')
        const textNote2 = ticket.isDomestic ? t('reservation.sms.note2Domestic') : t('reservation.sms.note2International')
        result += `${textNote1}\n${textNote2}\n\n`
      })

      return result
    }
    // !SECTION

    function setSmsContents(vm) {
      smsContents.value = [
        vm.getSmsContent(key => vm.$t(key, 'vi'), true, false, isCombinateSMS.value),
        vm.getSmsContent(key => removeAccents(vm.$t(key, 'vi')), true, true, isCombinateSMS.value),
        vm.getSmsContent(key => vm.$t(key, 'en'), false, false, isCombinateSMS.value),
      ]

      // this.$set(smsContents.value, 0, this.getSmsContent(key => this.$t(key, 'vi'), true, false, isCombinateSMS.value))
      // this.$set(smsContents.value, 1, this.getSmsContent(key => removeAccents(this.$t(key, 'vi')), true, true, isCombinateSMS.value))
      // this.$set(smsContents.value, 2, this.getSmsContent(key => this.$t(key, 'en'), false, false, isCombinateSMS.value))
    }

    const vm = getCurrentInstance().proxy
    function onShowModalSms() {
      // setTicketsData()
      setSmsContents(this)
    }
    // eslint-disable-next-line func-names
    watch(() => isCombinateSMS, () => {
      setSmsContents(vm)
    }, { deep: true })

    return {
      tabIndex,
      smsContents,
      disabledCopy,
      // flights,
      // paxs,
      // price,

      copySmsContentToClipboard,
      getAirportByAirportCode,
      getAirlineNameByCode,
      getBaggagesByBookingClass,
      getHandBaggage,
      getFreeBaggage,
      getSmsContent,
      setSmsContents,
      onShowModalSms,
      isCombinateSMS,
    }
  },
}
</script>

<style lang="scss">
.modal-dialog.modal-reservation-sms {
  width: 640px !important;
  max-width: 640px !important;
}
</style>
